declare var window: any;
import { logger } from '@utils/logger';

export const wait = (ms: number) => new Promise((r, j) => setTimeout(r, ms));
export const inEditMode = (): boolean => {
	const result =
		window.MSOWebPartPageFormName !== undefined &&
		((document.forms[window.MSOWebPartPageFormName] &&
			document.forms[window.MSOWebPartPageFormName].MSOLayout_InDesignMode &&
			'1' === document.forms[window.MSOWebPartPageFormName].MSOLayout_InDesignMode.value) ||
			(document.forms[window.MSOWebPartPageFormName] && document.forms[window.MSOWebPartPageFormName]._wikiPageMode && 'Edit' === document.forms[window.MSOWebPartPageFormName]._wikiPageMode.value));
	return result || false;
};
export const debug = (data?, message?, level?) => {
	const inDebug = Url.getUrlKeyValue('debug') == 'true';
	//if (inDebug && message) logger.log(message + ': ',data)
	//if (inDebug && !message) logger.log(data)

	switch (true) {
		case inDebug === true && message !== undefined:
			console.log(message.toUpperCase() + ': ', data);
			break;
		case inDebug === true && message === undefined:
			console.log(data);
			break;
		//default:console.log('no debug match');
	}
};
export class Url {
	public static getRelativeUrl = (absoluteUrl: string): string => {
		if (absoluteUrl.toLowerCase().indexOf('http') !== 0) {
			return absoluteUrl;
		}
		return `/${absoluteUrl.split('/').slice(3).join('/')}`.replace(/\/\//g, '/');
	};

	public static getAbsoluteUrl = (relativeUrl: string): string => {
		const host = window.location.href.split('/').slice(0, 3).join('/');
		return `${host}${relativeUrl}`;
	};

	public static getUrlKeyValue = (key: string, url: string = window.location.search): string => {
		return Url.getUrlKeyPairs(url)[key];
	};

	public static getUrlKeyPairs = (url: string = window.location.search): { [key: string]: string } => {
		return url
			.replace('?', '')
			.split('&')
			.reduce((res, keyPair) => {
				const [key, value] = keyPair.split('=');
				res[key] = decodeURIComponent(value);
				return res;
			}, {});
	};
}

export const checkElement = async (selector, callback?, timeout?, log?) => {
	const startTime = Date.now();
	while (document.querySelector(selector) === null) {
		// while selector is null...
		if (timeout && Date.now() - startTime > timeout) {
			if (log) logger.log('timeout for - ', selector);
			return;
		} else await new Promise((resolve) => requestAnimationFrame(resolve));
	}
	if (log) logger.log('finished - ', selector); // log if enabled
	if (callback) callback(); // run callback
	return document.querySelector(selector); // when done....... return selector.... or do whatever else....
};

export const getLoginLink = (useNola?) => {
  if (useNola){
    return "https://apply.mlmins.com/nola/login?mlmins=true"
  } else {
    const logpg = `${_spPageContextInfo.siteAbsoluteUrl}/auth/pages/login.aspx?ReturnUrl=${encodeURIComponent(
      `/_layouts/15/Authenticate.aspx?Source=${encodeURIComponent(_spPageContextInfo.serverRequestPath)}&Source=${_spPageContextInfo.serverRequestPath}`
    )}`;
    return logpg;
  }

};

// Hide 'Recent' in menu
export const removeRecent = async () => {
	ExecuteOrDelayUntilBodyLoaded(() => {
		Array.from(document.querySelectorAll('#sideNavBox .ms-core-listMenu-item')).forEach((ele, index) => {
			if (ele.textContent !== null && ele.parentElement !== null && ele.textContent.indexOf('Recent') !== -1) {
				ele.parentElement.style.display = 'none';
			}
		});
	});
};

export const toggleClass = (el, cls) => {
	if (hasClass(el, cls)) {
		const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
		el.className = el.className.replace(reg, ' ');
	} else {
		el.className = el.className + ' ' + cls;
	}
};

export const hasClass = (el, cls) => {
	try {
		return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') === -1 ? false : true;
	} catch (hc) {
		return false;
	}
};

export const loadCSS = (url) => {
	const head = document.getElementsByTagName('head')[0];
	const style = document.createElement('link');
	style.type = 'text/css';
	style.rel = 'stylesheet';
	style.href = url;
	head.appendChild(style);
};

export const loadJS = (url, location, isAsync, onLoadFunc) => {
	const scriptTag: any = document.createElement('script');
	scriptTag.src = url;
	scriptTag.async = isAsync;
	if (onLoadFunc) scriptTag.onload = onLoadFunc;
	if (onLoadFunc) scriptTag.onreadystatechange = onLoadFunc;
	if (!location) location = document.body;
	location.appendChild(scriptTag);
};

export const stringContains = (Mainstring, searchString) => {
	if (Mainstring.indexOf(searchString) === -1) return false;
	else return true;
};

export const hideWebpart = (_webpartidTD) => {
	const tables: any = document.getElementById(_webpartidTD);
	if (!tables) return;
	const rows: any = tables.getElementsByTagName('tr');
	if (!rows) return;
	const value = rows(2).innerText;

	if (stringContains(value, 'There are no items to show in this view of the')) {
		tables.style.display = 'none';
	}
};

export const loadScripts = (array, callback) => {
	const loader = (src, handler) => {
		const script: any = document.createElement('script');
		script.src = src;
		script.async = true;
		script.onload = script.onreadystatechange = () => {
			script.onreadystatechange = script.onload = null;
			handler();
		};
		const head = document.getElementsByTagName('head')[0];
		(head || document.body).appendChild(script);
	};

	(function run() {
		if (array.length !== 0) {
			loader(array.shift(), run);
		} else {
			callback && callback();
		}
	})();
};
