export const mlmTheme = {
  palette: {
    themePrimary: '#0072a6',
    themeLighterAlt: '#f1f8fb',
    themeLighter: '#cae5f1',
    themeLight: '#a0cfe4',
    themeTertiary: '#51a3c9',
    themeSecondary: '#157fb0',
    themeDarkAlt: '#006695',
    themeDark: '#00567e',
    themeDarker: '#003f5d',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#7596b1',
    neutralSecondary: '#537998',
    neutralPrimaryAlt: '#385f7e',
    neutralPrimary: '#000e1a',
    neutralDark: '#11314c',
    black: '#061e33',
    white: '#ffffff'
  }
};
