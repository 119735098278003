import ResizeObserver from 'resize-observer-polyfill';
import { toggleClass, hasClass } from '@utils/common';
import './responsive.scss';
declare var window: any;
declare var SP: any;

export class Responsive {
  private initState: boolean = false;
  private viewportState: boolean = false;
  private designbuilderState: boolean = false;

  public cloneSPIdNodes(el) {
    el.id = el.getAttribute('id') + '_mobileClone';
    const childs = el.getElementsByTagName('*');

    /* Regex to Get all SiteActionsMenuMain and SiteActionsMenu occurences in childs attributes */
    const regex = new RegExp('(.*?zz.*?(?:_SiteActionsMenuMain|_SiteActionsMenu))', 'g');

    for (const child of childs) {
      if (child.id) {
        /* Update All Attributes that contains Regex ID */
        for (const a of child.attributes) {
          if (a.name !== 'id') {
            a.value = a.value.replace(regex, '$1_mobileClone');
          }
        }
        /* Update Child ID */
        child.id = child.getAttribute('id') + '_mobileClone';
      }
    }
    return el;
  }

  public responsivizeSettings() {
    /* return if no longer on Settings page */
    if (window.location.href.indexOf('/settings.aspx') < 0) {
      return;
    }

    /* find the Settings root element, or wait if not available yet */
    const settingsRoot = document.getElementsByClassName('ms-siteSettings-root')[0];
    if (!settingsRoot) {
      setTimeout(this.responsivizeSettings, 100);
      return;
    }

    const linkSettingsSectionsLvl: any = settingsRoot.getElementsByClassName('ms-linksection-level1');
    for (const sec of linkSettingsSectionsLvl) {
      const settingsDiv = document.createElement('div');
      settingsDiv.className = 'mlm-settingsdiv';
      settingsDiv.appendChild(sec.getElementsByTagName('img')[0]);
      settingsDiv.appendChild(sec.getElementsByClassName('ms-linksection-textCell')[0]);
      settingsRoot.appendChild(settingsDiv);
    }
    if (settingsRoot.getElementsByTagName('table')[0]) {
      settingsRoot.removeChild(settingsRoot.getElementsByTagName('table')[0]);
    }
  }

  public responsivizeDesignbuilder() {
    /* return if no longer on Settings page */
    if (window.location.href.indexOf('/designbuilder.aspx') < 0) {
      return;
    }
    if (!this.designbuilderState) {
      this.MLMInitializeDesignBuilderCUI(window._spPageContextInfo.currentCultureLCID, window._spPageContextInfo.currentCultureName, '.', document.getElementsByTagName('html')[0].getAttribute('dir'));
    }
  }

  // a little bit of code straight from sharepoint
  public MLMInitializeDesignBuilderCUI(k, h, e, f) {
    try {
      const i = new window.CUI.BuildOptions();
    } catch (l) {
      return;
    }
    const b = new window.CUI.BuildOptions();
    b.lazyMenuInit = true;
    b.trimmedIds = {};
    b.attachToDOM = false;
    b.validateServerRendering = false;
    b.fixedPositioningEnabled = false;
    b.dataExtensions = null;
    b.clientID = 'ms-designbuilder-cuicontainer_mobileClone';
    try {
      const j = SP.Ribbon.PageManager.get_instance();
    } catch (l) {
      return;
    }
    const c = document.getElementById('ms-designbuilder-cuicontainer_mobileClone');
    if (!Boolean(c)) {
      return;
    }
    if (c !== null) c.innerHTML = '';
    const a = new window.CUI.RootProperties();
    a.Culture = h;
    a.DecimalSeparator = e;
    a.RootEventCommand = 'designBuilderRootEventCommand';
    a.ImageDownArrow = window.GetThemedImageUrl('spcommon.png');
    a.ImageDownArrowTop = '-256';
    a.ImageDownArrowLeft = '-104';
    a.TextDirection = f;
    const d = SP.Ribbon.PageManager.get_instance();
    const g = new window.CUI.Builder(b, c, d);
    window.g_designBuilderControlRoot = new window.CUI.StandaloneRoot('DesignBuilderTools', a);
    window.g_designBuilderControlRoot.setBuilder(g);
    d.addRoot(window.g_designBuilderControlRoot);
    this.MLMAddControlsToCUI();
  }
  // from sharepoint
  public MLMAddControlsToCUI() {
    /* a: ; */
    const d = window.g_designData;
    const i = window.g_designBuilderControlRoot;
    let f: any = null;
    const j = encodeURIComponent(d.newThemesLocation.toUpperCase());
    let g = true;
    const o = d.overrideThemesLocation.toLowerCase() === 'true';
    if (o) {
      const B = window.g_desbld_designGuid;
      const y = window.g_desbld_designVersion;
      g = window.g_desbld_designGuid == null || window.g_desbld_designGuid === 'undefined' || window.g_desbld_designVersion == null || window.g_desbld_designVersion === 'undefined';
      f = window.GetSelectedDesignPackageFolder(g, B, y);
      if (f !== null && f !== '') {
        f = f.toUpperCase();
      }
    }
    const k = document.getElementById('ms-designbuilder-cuicontainer_mobileClone');
    const D = window.window.CreateLabelElement(Strings.STS.L_DesignBuilderToolsPaletteLabel, 'ms-designbuilder-palette-Medium');
    if (k !== null) k.appendChild(D);
    let n = 0;
    let h: any = [];
    let c = false;
    let e;
    let a;
    let b;
    const w = window.g_desbld_themeUrl;

    for (a of d.themes) {
      b = a.toUpperCase();
      if (!o || (g && b.indexOf(j) === -1) || (!g && (b.indexOf(j) === -1 || b.indexOf(f) !== -1))) {
        const s = d.themes[a];
        if (!(window.OffSwitch == null || window.OffSwitch.IsActive('F21AA66C-5CE7-4EA8-AE05-BA6E16A1A182'))) {
          if (!c && window.unescapeProperly(s.ServerRelativeUrl.toUpperCase()) === window.unescapeProperly(window.toUpperCase())) {
            e = a;
            c = true;
          }
        } else if (!c && decodeURI(s.ServerRelativeUrl.toUpperCase()) === decodeURI(window.toUpperCase())) {
          e = a;
          c = true;
        }
        h.push(window.CreatePaletteGalleryButtonXml(a, s, n++));
      }

    }

    let m = h.join('');
    const z = [
      '<DropDown Id="ms-designbuilder-palette" Alt="',
      Strings.STS.L_DesignBuilderToolsPaletteAlt,
      '" Command="PalettePickerSelected" QueryCommand="PalettePickerQuery" CommandPreview="PalettePickerPreview" CommandRevert="PalettePickerPreviewRevert" Width="111px" SelectedItemDisplayMode="Menu" InitialItem="',
      e,
      '"><Menu Id="DesignBuilderTools.Palette.Menu"><MenuSection Id="ms-designbuilder-palette-menusection" MaxHeight="269px" Scrollable="true"><Controls Id="DesignBuilderTools.Palette.Menu.MenuSection.Controls">',
      m,
      '</Controls></MenuSection></Menu></DropDown>'
    ];
    i.addControl('DesignBuilderTools.Palette', z.join(''));
    const H = i.getDOMElementForControlDisplayMode('DesignBuilderTools.Palette', 'Medium');
    if (k !== null) k.appendChild(H);
    const E = window.CreateLabelElement(Strings.STS.L_DesignBuilderToolsLayoutLabel, 'ms-designbuilder-layout-Medium');
    if (k !== null) k.appendChild(E);
    n = 0;
    h = [];
    c = false;
    e = null;
    const v = window.g_desbld_masterUrl;
    for (a of d.preview) {
      b = a.toUpperCase();
      if (!o || (g && b.indexOf(j) === -1) || (!g && (b.indexOf(j) === -1 || b.indexOf(f) !== -1))) {
        const K = d.preview[a];
        if (!c && decodeURI(a.toUpperCase()) === decodeURI(v.toUpperCase())) {
          e = a;
          c = true;
        }
        h.push(window.CreateLayoutButtonXml(a, K, n++));
      }
    }
    m = h.join('');
    const A = [
      '<DropDown Id="ms-designbuilder-layout" Alt="',
      Strings.STS.L_DesignBuilderToolsLayoutAlt,
      '" Command="LayoutPickerSelected" QueryCommand="LayoutPickerQuery" CommandPreview="LayoutPickerPreview" CommandRevert="LayoutPickerPreviewRevert" Width="103px" InitialItem="',
      e,
      '"><Menu Id="DesignBuilderTools.Layout.Menu"><MenuSection Id="ms-designbuilder-layout-menusection"><Controls Id="DesignBuilderTools.Layout.Menu.MenuSection.Controls">',
      m,
      '</Controls></MenuSection></Menu></DropDown>'
    ];
    i.addControl('DesignBuilderTools.Layout', A.join(''));
    const J = i.getDOMElementForControlDisplayMode('DesignBuilderTools.Layout', 'Medium');
    if (k !== null) k.appendChild(J);
    const G = window.CreateLabelElement(Strings.STS.L_DesignBuilderToolsFontLabel, 'ms-designbuilder-fontscheme-Medium');
    if (k !== null) k.appendChild(G);
    n = 0;
    h = [];
    c = false;
    e = null;
    let p = null === window.g_desbld_fontSchemeUrl ? null : window.g_desbld_fontSchemeUrl;
    if (null === p || 'DEFAULT' === p.toUpperCase()) {
      const r = window.GetItemIgnoreCase(d.preview, v);
      if (Boolean(r) && Boolean(r.defaultFontScheme)) {
        p = r.defaultFontScheme;
      }
    }
    const u = p.toUpperCase();
    const C = d.fontSchemeDuplicateKeyCount;
    const l = new Array(C);
    let q;
    for (a of d.fontSchemes) {
      q = d.fontSchemeDuplicateKeys[a];
      if (!Boolean(l[q])) {
        l[q] = a;
      } else if (!c && decodeURI(a.toUpperCase()) === decodeURI(u)) {
        l[q] = a;
      }
    }
    c = false;

    for (const al of l) {
      a = al;
      b = a.toUpperCase();
      if (!o || (g && b.indexOf(j) === -1) || (!g && (b.indexOf(j) === -1 || b.indexOf(f) !== -1))) {
        const I = d.fontSchemes[a];
        if (!c && decodeURI(b) === decodeURI(u)) {
          e = a;
          c = true;
        }
        h.push(window.CreateFontSchemeButtonXml(a, I, n++));
      }
    }
    m = h.join('');
    const x = [
      '<DropDown Id="ms-designbuilder-fontscheme" Alt="',
      Strings.STS.L_DesignBuilderToolsFontSchemeAlt,
      '" Command="FontSchemePickerSelected" QueryCommand="FontSchemePickerQuery" Width="132px" SelectedItemDisplayMode="Menu" InitialItem="',
      e,
      '"><Menu Id="DesignBuilderTools.FontScheme.Menu"><MenuSection Id="ms-designbuilder-fontscheme-menusection"><Controls Id="DesignBuilderTools.FontScheme.Menu.MenuSection.Controls">',
      m,
      '</Controls></MenuSection></Menu></DropDown>'
    ];
    i.addControl('DesignBuilderTools.FontScheme', x.join(''));
    const F = i.getDOMElementForControlDisplayMode('DesignBuilderTools.FontScheme', 'Medium');
    if (k !== null) k.appendChild(F);
    i.pollForStateAndUpdate();
    this.designbuilderState = true;
  }

  public mlmNavGeneration(navId) {
    const nav = document.getElementById(navId);
    let clonedNav: any = '';
    if (nav) {
      clonedNav = nav.cloneNode(true);
      clonedNav = this.cloneSPIdNodes(clonedNav);
      clonedNav.className += ' ms-qSuggest-listSeparator';

      /* Sub nodes accordion */
      const navNodes = clonedNav.querySelectorAll('li');

      for (const nnode of navNodes) {
        const navItem = nnode.getElementsByClassName('menu-item')[0];
        const navRow = document.createElement('div');
        navRow.className = 'ms-core-menu-item';
        const checkChild = nnode.getElementsByTagName('ul');
        if (checkChild.length > 0 && navItem) {
          if (!hasClass(nnode, 'dynamic-children')) {
            // nnode.className = nnode.className + ' dynamic-children';
            nnode.className = nnode.className + 'dynamic-children';
          }
          /* Add button to preserve html link */
          const expandBtn = document.createElement('button');
          expandBtn.type = 'button';
          navRow.appendChild(expandBtn);
          expandBtn.addEventListener('click', function () {
            if (this.parentNode !== null) toggleClass(this.parentNode.parentNode, 'expand');
            return false;
          });
        }
        if (navItem) {
          nnode.insertBefore(navRow, nnode.firstElementChild);
          navRow.appendChild(navItem);
        }
        nnode.insertBefore(navRow, nnode.firstElementChild);
        /* Change Edit Link navigation */
        const l = nnode.querySelector('a.ms-navedit-editLinksText');
        if (l) {
          l.removeAttribute('onclick');
          l.href = window._spPageContextInfo.webServerRelativeUrl + '/_layouts/15/AreaNavigationSettings.aspx';
        }
      }

    }
    return clonedNav;
  }

  public ensureElemCreation(p, c) {
    let r = false;
    if (!c) {
      if (!hasClass(p, 'mlm-nodeListener')) {
        p.className += ' mlm-nodeListener';
        p.addEventListener('DOMNodeInserted', () => this.setUpToggling);
      }
    } else {
      r = true;
    }
    return r;
  }

  public uiBurger() {
    const newTopItem = document.createElement('div');
    newTopItem.className = 'o365cs-nav-topItem o365cs-rsp-m-hide o365cs-rsp-tn-hideIfAffordanceOn mobile-only';
    const newTopItemChild = document.createElement('div');
    const newTopNavItem = document.createElement('button');
    newTopNavItem.type = 'button';
    newTopNavItem.className = 'o365cs-nav-item o365cs-nav-button ms-bgc-tdr-h o365button o365cs-topnavText o365cs-navMenuButton ms-bgc-tp ms-button-emphasize mobile-only';
    newTopNavItem.id = 'MLM_MainLink_Hamburger';
    newTopNavItem.setAttribute('role', 'menuitem');
    newTopNavItem.setAttribute('aria-disabled', 'false');
    newTopNavItem.setAttribute('aria-selected', 'false');
    newTopNavItem.setAttribute('aria-label', 'Open the menu to access additional app options');

    const newTopNavText = document.createElement('span');
    newTopNavText.className = 'ms-rteThemeBackColor-1-0 ms-Icon--GlobalNavButton';
    newTopNavText.setAttribute('aria-hidden', 'true');

    newTopNavItem.addEventListener('click', () => {
      this.displayMLMPanel();
      this.responsivizeDesignbuilder();
      return false;
    });
    newTopNavItem.appendChild(newTopNavText);
    newTopItemChild.appendChild(newTopNavItem);
    newTopItem.appendChild(newTopItemChild);

    return newTopItem;
  }

  public displayMLMPanel() {
    const panel = document.getElementById('MLMNavPanel');
    const panelPage = document.getElementById('MLMPanelPage');
    if (panel) {
      toggleClass(panel, 'MLMPanelEnabled');
    }
    if (panelPage) {
      toggleClass(panelPage, 'MLMPanelEnabled');
    }
  }

  public init() {
    if (!this.initState) {
      let currentScriptUrl: any;
      const currentScript = document.getElementById('MLMResponsiveUI') as HTMLScriptElement;

      if (currentScript) {
        currentScriptUrl = currentScript.src;
      }
      this.listenForTitleRowChange();
      this.setUpToggling();
      this.setUpSuiteBarToggling();
      this.responsivizeSettings();
      /* Also listen for dynamic page change to Settings page */
      window.onhashchange = () => {
        this.responsivizeSettings();
      };

      const originalResizeFunction = window.FixRibbonAndWorkspaceDimensions;

      /* Then define a new one */
      window.FixRibbonAndWorkspaceDimensions = () => {
        /* Let sharepoint do its thing */
        originalResizeFunction();
        /* Fix the body container width */
        const s4b = document.getElementById('s4-bodyContainer');
        const s4w = document.getElementById('s4-workspace');
        if (s4b && s4w) s4b.style.width = s4w.offsetWidth + 'px';

      };
    }

    /* Init function is done */
    this.initState = true;
  }

  public listenForTitleRowChange() {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        const panel = document.getElementById('MLMNavPanel');
        if (panel) panel.style.top = `${height.toString()}px`
      };
    });
    ro.observe(document.getElementById('s4-titlerow') as HTMLElement);
  }

  public addViewport() {
    if (!this.viewportState) {
      const head = document.getElementsByTagName('head')[0];
      const viewport = document.createElement('meta');
      viewport.name = 'viewport';
      viewport.content = window.devicePixelRatio === 2 ? 'width=device-width, user-scalable=yes, initial-scale=.5'
        : 'width=device-width, user-scalable=yes, initial-scale=1.0';

      const appleMeta = document.createElement('meta');
      appleMeta.name = 'apple-mobile-web-app-capable';
      appleMeta.content = 'yes';
      head.appendChild(viewport);
      head.appendChild(appleMeta);
    }
    this.viewportState = true;
  }

  public setUpToggling() {
    /* If current window is a Modal Dialog */
    const mDialog = document.getElementsByClassName('ms-dialogBody');
    if (mDialog && mDialog.length > 0) {
      return;
    }

    /* If it is already responsivized, return */
    if (document.getElementById('MLM_MainLink_Hamburger')) {
      return;
    }

    /* Get Top Bar */
    const topBar = document.getElementById('menuContainer');
    const topNavLeft = document.getElementById('panelContainer');
    if (topNavLeft) {
      /* Add burger button */

      /* If it is already responsivized, return */
      if (!document.getElementById('MLM_MainLink_Hamburger')) {
        // const ttlrow = document.getElementById('titleAreaBox');
        const ttlrow = document.getElementById('menuContainer');
        if (ttlrow !== null) ttlrow.insertBefore(this.uiBurger(), ttlrow.firstChild);

      }

      /* Check if left panel already exist (To fix MDS feature effect) */
      if (document.getElementById('MLMNavPanel')) {
        return;
      }
      /* Left Panel */
      const spSuiteBar = document.getElementById('panelContainer');
      if (!spSuiteBar) {
        return;
      }

      /* Create MLM Panel Page that separate MLMPanel from Page content and allow click anywhere to close the panel */
      const mlmPanelPage = document.createElement('div');
      mlmPanelPage.id = 'MLMPanelPage';

      // #MLMPanelPage
      const mlmNavPanel = document.createElement('div');
      mlmNavPanel.id = 'MLMNavPanel';
      /* Hide with ms-hide to avoid UI load effect */
      mlmNavPanel.className = 'ms-rteThemeBackColor-1-0 ms-dialogHidden mobile-only ms-hide';

      const mlmContentNavPanel = document.createElement('div');
      /* Content Panel */
      mlmContentNavPanel.id = 'MLMContentNavPanel';

      /* TOP NAV support */
      const spTopNav = this.mlmNavGeneration('mlm-nav');
      // const spTopNav = this.mlmNavGeneration('navContainer');
      if (spTopNav) {
        mlmContentNavPanel.appendChild(spTopNav);
      }

      // QUICK LAUNCH nav support
      /*
            const spQLNav = this.mlmNavGeneration('DeltaPlaceHolderLeftNavBar');
            if (spQLNav) {
              mlmContentNavPanel.appendChild(spQLNav);
              document.getElementById('DeltaPlaceHolderLeftNavBar').addEventListener('DOMSubtreeModified', function () {
                const mlmCPanel = document.getElementById('MLMContentNavPanel');
                if (mlmCPanel) {
                  const clonedOrigin = this.mlmNavGeneration(this.id);
                  const old = mlmCPanel.querySelector('#' + clonedOrigin.id);
                  if (old) {
                    old.parentNode.removeChild(old);
                  }
                  const bottomDiv = mlmCPanel.getElementsByClassName('MLMPanelBottom')[0];
                  if (bottomDiv) {
                    mlmCPanel.insertBefore(clonedOrigin, bottomDiv);
                  } else {
                    mlmCPanel.appendChild(clonedOrigin);
                  }
                }
              });
            }

            /* Add bottom space to compensate for lack of height */
      const panelBottom = document.createElement('div');
      panelBottom.className = 'MLMPanelBottom';
      mlmContentNavPanel.appendChild(panelBottom);

      /* Add Left Panel to page */
      mlmNavPanel.appendChild(mlmContentNavPanel);
      if (spSuiteBar.parentElement !== null) spSuiteBar.parentElement.insertBefore(mlmNavPanel, spSuiteBar.nextSibling);
      /* Add MLMPanel Page to content */
      if (spSuiteBar.parentElement !== null) spSuiteBar.parentElement.insertBefore(mlmPanelPage, mlmNavPanel.nextSibling);
      /* Add event click on MLM Panel Page to close MLM Panel menu when click on it */
      mlmPanelPage.addEventListener('click', () => {
        this.displayMLMPanel();
        return false;
      });
    }
  }

  public setUpSuiteBarToggling() {
    const suiteLinks = document.getElementById('DeltaSuiteLinks');
    if (document.getElementById('suiteBar')) {
      /* Open Responsive Bar Button that replace Suite Bar Links */
      const suiteBarToggle = document.createElement('button');
      suiteBarToggle.id = 'mlm-suiteBar-open';
      suiteBarToggle.className = 'mobile-only ms-button-emphasize';
      suiteBarToggle.type = 'button';
      const bulletStyle = document.createElement('span');
      bulletStyle.className = 'ms-rteThemeBackColor-1-0';
      bulletStyle.setAttribute('aria-hidden', 'true');
      suiteBarToggle.addEventListener('click', () => {
        if (document.getElementById('mlm-suiteBar')) {
          toggleClass(document.getElementById('mlm-suiteBar'), 'ms-hide');
        }
      });
      suiteBarToggle.appendChild(bulletStyle);
      if (suiteLinks !== null) suiteLinks.appendChild(suiteBarToggle);
    }
    // Responsive.Main.setUpSuiteBarRwd();
    this.setUpSuiteBarRwd();
  }

  public setUpSuiteBarRwd() {
    /* if it is already responsivized, return */
    if (document.getElementById('mlm-suiteBar')) {
      return;
    }
    if (document.getElementById('suiteBar')) {
      let suiteLinksBox;
      let suiteBarBtnClone;
      let suiteLinksBoxClone;
      const suiteBar = document.getElementById('suiteBar');
      /* Clone Suite Bar Buttons */
      const suiteBarBtn = document.getElementById('suiteBarButtons');
      if (suiteBarBtn !== null) suiteBarBtnClone = suiteBarBtn.cloneNode(true);
      suiteBarBtnClone = this.cloneSPIdNodes(suiteBarBtnClone);
      /* Clone Suite Bar links (Newsfeed, OneDrive, Sites, etc.) */
      suiteLinksBox = document.getElementById('suiteLinksBox');
      suiteLinksBoxClone = suiteLinksBox.cloneNode(true);
      suiteLinksBoxClone = this.cloneSPIdNodes(suiteLinksBoxClone);
      /* Create responsive bar */
      const suiteBarRwd = document.createElement('div');
      suiteBarRwd.id = 'mlm-suiteBar';
      suiteBarRwd.className = 'mobile-only ms-rteThemeBackColor-5-0 ms-hide';
      /* Close Responsive Bar Button */
      const closeSuiteBarRwd = document.createElement('button');
      closeSuiteBarRwd.id = 'mlm-suiteBar-close';
      closeSuiteBarRwd.type = 'button';
      closeSuiteBarRwd.className = 'ms-button-emphasize';
      closeSuiteBarRwd.addEventListener('click', () => {
        toggleClass(document.getElementById('mlm-suiteBar'), 'ms-hide');
      });
      /* Render Responsive Bar */
      suiteBarRwd.appendChild(closeSuiteBarRwd);
      suiteBarRwd.appendChild(suiteBarBtnClone);
      suiteBarRwd.appendChild(suiteLinksBoxClone);
      if (suiteBar && suiteBar.parentNode) suiteBar.parentNode.insertBefore(suiteBarRwd, document.getElementById('suiteBar'));
    }
  }
}
