import { PermissionKind, sp } from '@pnp/sp/presets/all';
import { checkElement, getLoginLink, hasClass, inEditMode, loadJS, removeRecent } from '@utils/common';
import { Responsive } from '@components/Responsive';
import { initRibbon, fullScreenMode } from '@components/Ribbon';
import { Url } from '@utils/url';
import { loadContext } from '@utils/env';
import { logger } from '@utils/logger';
import { setupPnp } from '@utils/odata';
import '@pnp/sp/lists';
import '@pnp/sp/items';
import '@pnp/sp/profiles';
import { getTheme, loadTheme } from '@uifabric/styling';
import { mlmTheme } from '@utils/mlmTheme';
import { GlobalSettings } from '@uifabric/utilities/lib/GlobalSettings';

loadTheme(mlmTheme);
const customizations = GlobalSettings.getValue('customizations');

declare var window: any;

if (Url.getUrlKeyValue('ctx')) logger.log(_spPageContextInfo);
if (inEditMode() === true) {
  document.body.classList.add('inEditMode');
}

loadContext().then(async () => {
  setupPnp();
  if (_spPageContextInfo.userId) {
    // only for logged in users
    // tslint:disable-next-line: no-floating-promises
    if (sp && sp.web) {
      sp.web.currentUserHasPermissions(PermissionKind.AddAndCustomizePages).then((perms) => {
        if (perms) {
          window.globalCollapse = rdom;
          rdom.init();
        }
      });
    }

  }
}).catch(logger.error);

const addRust = async () => {
  try {
    const ele = await checkElement('.mlm-global > .ms-core-listMenu-horizontalBox > .ms-core-listMenu-root');
    if (_spPageContextInfo.userId) {
      Array.from(document.querySelectorAll('.mlm-global li.static.dynamic-children')).forEach((element, index) => {
        if (element.textContent && element.textContent.startsWith('MyMLM')) {
          element.classList.add('mlm-rust-item');
        }
      });
    } else if (!_spPageContextInfo.userId) {
        const useNola = true;
        const loginText = useNola ? "Portal Log In" : "Log In"

        ele.insertAdjacentHTML('beforeend', `<li class="static mlm-rust-item"><a class="static menu-item ms-core-listMenu-item ms-displayInline ms-navedit-linkNode" href='${getLoginLink(useNola)}'><span class="additional-background ms-navedit-flyoutArrow"><span class="menu-item-text">${loginText}</span></span></a></li>`);

      const extended = document.getElementById('mlm-extended');
      if (extended) {
        extended.insertAdjacentHTML('afterbegin', `<div class='mlm-mobile-rust'><div class="mlm-mobile-rust-btn"><a class="login-link" href='${getLoginLink(useNola)}'>${loginText}</a></div></div>`);
      }

    }
  } catch (err) {
    logger.log(err);
  }
};

const responsiveStartup = async () => {
  const ui = new Responsive();
  ui.addViewport();
  ui.init();
};

const rdom: any = {
  topbars: localStorage.getItem('topBars'),
  isEditing: inEditMode(),
  init: () => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      try {
        await checkElement('#s4-ribbonrow', window.globalCollapse.createControls);
      } catch (err) {
        logger.log(err);
      }
    })();
  },

  createControls: async () => {
    // if (document.getElementById('officeToggle')) return;
    // const s4t = document.getElementById('s4-titlerow') as HTMLElement;
    const s4w = document.getElementById('s4-workspace') as HTMLElement;
    // const arrowDiv = '<div id="officeToggle" class="ribbon-toggle"><i class="ms-Icon ms-Icon--Add"></i></div>';
    // if (s4t) s4t.insertAdjacentHTML('beforeend', arrowDiv);

    const t = rdom.topbars;
    const rtoggle = await checkElement('.ribbon-toggle i');
    if (t === null) {
      localStorage.setItem('topBars', 'hidden');
      rtoggle.classList.remove('icon-twisted');
    } else {
      if (t === 'visible' || rdom.isEditing) {
        rtoggle.classList.add('icon-twisted');
        if (s4w) s4w.style.top = '0px';
        document.body.classList.add(...['ribbon-visible', 'opacity-full']);
        rtoggle.classList.add('icon-twisted');
      } else {
        if (s4w) s4w.classList.add('reset-position');
        rtoggle.classList.remove('icon-twisted');
      }
    }
    window.FixRibbonAndWorkspaceDimensions();
    rtoggle.classList.remove('hidden');
    rtoggle.addEventListener('click', rdom.toggle);
  },

  toggle(event) {
    const toggling = event.target.getAttribute('data-toggling');
    if (toggling === 'true') return;
    event.target.setAttribute('data-toggling', 'true');

    if (hasClass(event.target, 'icon-twisted')) {
      initRibbon();
    } else {
      fullScreenMode();
    }
  }
};

/*
const renderMarketing = async () => {
  logger.log(_spPageContextInfo);
  const web = Web(`${_spPageContextInfo.siteAbsoluteUrl}/data`);
  const banners = await web.lists.getByTitle('Banners').items.select('Title', 'ID', 'Position', 'ItemType', 'Banner/Title', 'Banner/Id', 'Banner/BannerContent').expand('Banner').filter(`Title eq '${_spPageContextInfo.serverRequestPath}'`).get();
  logger.log(banners);
};
*/

const addOnloadEvents = async () => {
  // window.sdkjs.addEventListener('load', window.BEJSSDK.construct(beoptions));
  window.addEventListener('load', () => {
    const logo: any = document.getElementById('ctl00_onetidProjectPropertyTitleGraphic');
    const footer = document.getElementById('footer') as HTMLElement;
    if (logo) logo.href = '/';
    if (footer) footer.style.display = 'flex';
    Array.from(document.querySelectorAll('.ms-webpart-chrome-title > span')).forEach((element, index) => element.setAttribute('title', ''));
  }, false);
};

const pageLoadTasks = [
  responsiveStartup,
  addRust,
  addOnloadEvents,
  removeRecent,
  // loadBrightEdge
];

const browserSupportsAllFeatures = () => {
  return window.Promise && window.fetch && window.Symbol;
};

const main = () => {
  Promise.all([
    pageLoadTasks.map((itm) => itm())
  ])
    .then((results) => {
      // logger.log(results);
      // logger.log('done');
      const theme = getTheme();
      (customizations as any).settings.theme.effects = { ...theme.effects };
      (customizations as any).settings.theme.spacing = { ...theme.spacing };
    })
    .catch((err) => logger.log(err));

};

main();

/*
if (browserSupportsAllFeatures()) {
  // Browsers that support all features run `main()` immediately.
  logger.log('all features supported');
  main();
} else {
  logger.log('need to load mlm-polyfills.js');
  main();
  // All other browsers loads polyfills and then run `main()`.
  // loadScript('/path/to/polyfills.js', main);
  loadJS('/_catalogs/masterpage/mlm/scripts/mlm-polyfills.js', document.head, true, main);
  // loadJS = (url, location, isAsync, onLoadFunc)
}
*/

/*
Promise.all([
  pageLoadTasks.map((itm) => itm())
])
  .then((results) => {
    // logger.log(results);
    // logger.log('done');
  })
  .catch((err) => logger.log(err));
  */
