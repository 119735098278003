import './ribbon.scss';
import { inEditMode } from '../../utils/common';
import { logger } from '@utils/logger';

declare var window: any;

export const initRibbon = () => {
  const rti = document.querySelector('.ribbon-toggle i');
  const mode = inEditMode();
  if (inEditMode() === true) {
    document.body.classList.remove('ribbon-visible');
    window.FixRibbonAndWorkspaceDimensions();
    if (rti !== null) rti.setAttribute('data-toggling', 'false');
    localStorage.setItem('topBars', 'hidden');
    window.FixRibbonAndWorkspaceDimensions();
    return;
  } else {
    if (rti !== null) rti.classList.remove('icon-twisted');
    document.body.classList.remove('ribbon-visible');
    if (rti !== null) rti.setAttribute('data-toggling', 'false');
    try {
      localStorage.setItem('topBars', 'hidden');
      window.FixRibbonAndWorkspaceDimensions();
    } catch (e) {
      return false;
    }
  }
};

export const fullScreenMode = () => {
  logger.log('fullscreenmode');
  const s4w = document.getElementById('s4-workspace');
  const rti = document.querySelector('.ribbon-toggle i');
  if (rti !== null) rti.classList.add('icon-twisted');
  if (s4w !== null) s4w.classList.remove(...['reset-position', 'opacity-full']);
  document.body.classList.add(...['ribbon-visible', 'opacity-full']);
  if (s4w !== null) s4w.style.top = '0px';
  // if (s4w !== null) s4w.style.top = '35px';
  if (rti !== null) rti.setAttribute('data-toggling', 'false');
  try {
    localStorage.setItem('topBars', 'visible');
    window.FixRibbonAndWorkspaceDimensions();
  } catch (e) {
    return false;
  }
};
